import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuard } from '@src/core/guards/auth.guard'
import { StructureGuard } from '@src/core/guards/structure.guard'

import { LayoutsModule } from '@src/layouts/layouts.module'
import { AuthLayoutComponent } from '@src/layouts/auth/auth.layout'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'adherent',
    pathMatch: 'full',
  },
  {
    path: 'adherent',
    component: AuthLayoutComponent,
    data: { roles: ['fiaf-member'] },
    loadChildren: () => import('./views/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard, StructureGuard],
  },
  {
    path: 'mot-de-passe',
    data: { pageTitle: 'Mot de passe' },
    loadChildren: () => import('./views/password/password.module').then((m) => m.PasswordModule),
  },
  {
    path: 'personne',
    data: { pageTitle: 'Personne' },
    loadChildren: () => import('./views/person/person.module').then((m) => m.PersonModule),
  },
  {
    path: 'structure',
    data: { roles: ['fiaf-member'], pageTitle: 'Structure' },
    loadChildren: () => import('./views/structure/structure.module').then((m) => m.StructureModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'financement',
    data: { roles: ['fiaf-member'], pageTitle: 'Financement' },
    loadChildren: () => import('./views/funding/funding.module').then((m) => m.FundingModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'adherent' },
]

@NgModule({
  imports: [LayoutsModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
