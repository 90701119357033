export const Environment = {
  API_PATH: 'api/public',
  TITLE: 'Espace Adhérent',
  LOGO: 'assets/logo-menu-adherent.svg',
  LAYOUT_MENU: [
    {
      separator: true,
    },
    {
      label: 'Demande financement',
      items: [
        { label: 'Consulter les demandes', icon: 'pi pi-folder text-white', routerLink: 'demandes' },
        { label: 'Faire une demande', icon: 'pi pi-plus text-white', routerLink: '/financement' },
        {
          label: "Tester l'éligibilité",
          icon: 'pi pi-external-link text-white',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSdTGOKkt_nFYiZxjW9pvw24A5mg82EqBSaNhQ-HiEkchYHnjg/viewform',
        },
        {
          label: 'Financer un parcours diplômant',
          icon: 'pi pi-external-link text-white',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSdHI5KRmwtDnCxSQ8qwWvoMutFh_aBBJZ8gqLCOV6U-YO4iqg/viewform',
        },
      ],
    },
    {
      label: 'Structure rattachées',
      items: [
        { label: 'Consulter les structures', icon: 'pi pi-building text-white', routerLink: 'structures' },
        { label: 'Ajouter une structure', icon: 'pi pi-plus text-white', routerLink: '/structure' },
      ],
    },
    {
      label: 'Versements',
      items: [
        {
          label: 'Saisir un versement',
          icon: 'pi pi-calendar text-white',
          url: 'https://forms.gle/9JxGaX4Lhtfz4jzY7',
        },
        { label: 'Consulter les versements', routerLink: 'versements' },
      ],
    },
    {
      label: 'Accompagnement',
      items: [
        {
          label: 'Demander un rendez-vous',
          icon: 'pi pi-calendar text-white',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSf5morY1yn5_70Qk1w9czrDlCU15VpFFgTzqn2chUGrqRAu3A/viewform',
        },
      ],
    },
    {
      label: 'Programmation du FIAF',
      items: [
        {
          label: 'Consulter les formations',
          icon: 'pi pi-book text-white',
          url: 'https://www.fiaf.nc/programmation/programmation-du-fiaf',
        },
      ],
    },
    {
      label: 'Jeux du FIAF',
      items: [
        {
          label: 'Employeurs - Eléments',
          icon: 'pi pi-calendar text-white',
          url: 'https://element.fiaf.nc',
        },
      ],
    },
  ],
}
