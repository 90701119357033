import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { StructureService } from '@src/services/structure.service'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StructureGuard implements CanActivate {
  constructor(
    private structureService: StructureService,
    private router: Router,
    private http: HttpClient
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    if ((await firstValueFrom(this.structureService.getStructuresCount())).count == 0) {
      this.http.get('api/public/v1/drafts/structure/latest').subscribe({
        next: (draft) => {
          this.router.navigate(['/structure/enregistrer'], { state: { draft } })
          return false
        },
        error: () => {
          this.router.navigate(['/structure/rattachement'])
          return false
        },
      })
    }
  }
}
